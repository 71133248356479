@import 'modules/_m_top'

.spNone
  +mediaSp()
    display none !important

.pcNone
  +mediaPc()
    display none !important

.m_bold
  font-weight bold !important

.fullW
  width 100%

.halfW
  width 50%

// 汎用的に使う、ごく小規模な装飾のみ追記してください（css1行のみで成り立つような記述）
// 基本的にはmodules/以下で新しくファイルを作り、importする形で要素を追記すること
