// ==========================================================================
// var
// ==========================================================================

fontset-gothic = "Lucida Grande", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, "メイリオ", sans-serif
breakPoint = 736px
breakPointLarge = 2000px
text01 = #333333 // 通常文字色
text02 = #a3a3a3 // 通常テキストグレー
themeWhite = #fff

themeColor01 = #f5f5f5 // 薄い背景色
themeColor02 = #dcebf5 // 背景色薄い水色
themeColor03 = #15264e // 背景色藍色
themeColor04 = #32487d // 背景色藍色02
themeColor05 = #413f3f // 濃い灰色

line01 = #e0e0e0

image_path = '/resources/images'
css_path = '/resources/css'
js_path = '/resources/js'

// ==========================================================================
// mixin
// ==========================================================================


//カーニング自動調整
kerning()
  -webkit-font-feature-settings "pkna"
  font-feature-settings "pkna"
  letter-spacing 0.03em

//メディアクエリ
mediaSp()
  @media only screen and (max-width breakPoint)
    {block}
mediaPc()
  @media print, screen and (min-width breakPoint + 1)
    {block}
mediaPcLarge()
  @media print, screen and (min-width breakPointLarge + 1)
    {block}
media(responsive)
  @media only screen and (max-width responsive)
    {block}

//フォントずれIEハック
ieHeight(ieHeight)
  @media all and (-ms-high-contrast: none)
    line-height ieHeight
    *::-ms-backdrop
      line-height ieHeight
  &:not(:target)
    line-height ieHeight + \9

// clearfix
clearfix()
  &:after
    content "."
    display block
    clear both
    height 0
    visibility hidden

//gradient
gradient(gradient1, gradientStart, gradient2, gradientEnd)
  background linear-gradient(to bottom, gradient1 gradientStart, gradient2 gradientEnd)

//フォントファミリー
gothic()
  font-family fontset-gothic

//ボタンのデザインリセット
inputReset()
  border-radius: 0
  -webkit-box-sizing: content-box
  -webkit-appearance: none
  appearance: none
  border: none
  box-sizing: border-box
  &::-webkit-search-decoration
    display: none
  &::focus
    outline-offset: -2px

// レスポンシブの縦余白指定
mt-h(mth)
  margin-top mth
  +mediaSp()
    margin-top mth * 0.5
pt-h(pth)
  padding-top pth
  +mediaSp()
    padding-top pth * 0.5
mb-h(mbh)
  margin-bottom mbh
  +mediaSp()
    margin-bottom mbh * 0.5
pb-h(pbh)
  padding-bottom pbh
  +mediaSp()
    padding-bottom pbh * 0.5

mt(mtpc, mtsp)
  margin-top mtpc
  +mediaSp()
    margin-top mtsp
mb(mbpc, mbsp)
  margin-bottom mbpc
  +mediaSp()
    margin-bottom mbsp
pt(ptpc, ptsp)
  margin-top ptpc
  +mediaSp()
    margin-top ptsp
pb(pbpc, pbsp)
  margin-top pbpc
  +mediaSp()
    margin-top pbsp
