/* ==========================================================================*/
/* 全体 */
/* ==========================================================================*/
a
  color themeColor04
#map
  width 100%
  height 300px
  margin-top 10px
.pcBr
  display block
.spBr
  display none
+mediaSp()
  .pcBr
    display none
  .spBr
    display block
  
/* ==========================================================================*/
/* ヘッダー　フッター */
/* ==========================================================================*/

.m_header
  position relative
  margin-bottom -160px
  +mediaSp()
    margin-bottom -200px
  .m_mvBack
    background themeColor04
    min-height 373px
    margin-right 40%
    margin-top 170px
  .m_mvImg
    background url(image_path + "/mainImg.jpg") center top no-repeat
    height 100%
    min-height 440px
    margin-left 32%
    margin-top -480px
    background-size cover
  +mediaPc()
    .m_mvImg
      margin-left 32%
  +mediaSp()
    .m_mvBack
      min-height 212px
      margin-right 25%
      margin-top 90px
    .m_mvImg
      min-height 266px
      margin-left 33%
      margin-top -289px
  +mediaPcLarge()
    .m_mvImg
      margin-left 40%
  h1
    position relative
    top -440px
    width 1000px
    margin auto
    +mediaSp()
      top -280px
      width 92%
      margin 15px 4% 0
      img
        width 88px
  .m_mvTxts
    position relative
    top -320px
    width 1000px
    margin auto
    color themeWhite
    h2
      font-size 4.7rem
    +mediaSp()
      top -230px
      width 92%
      margin 5px 4% 0
      h2
        font-size 2.8rem
        line-height 1.4
        margin-bottom 10px
footer
  .footer_copy
    background themeColor05
    color themeWhite
    text-align center
    padding 15px 0
    font-size 1.4rem
      

/* ==========================================================================*/
/* メニュー */
/* ==========================================================================*/
.m_menu
  display flex
  justify-content space-between
  align-items center
  margin 15px auto
  width 910px
  li
    a
      color text01
      text-decoration none
      font-size 1.3rem
      position relative
      display inline-block
      text-decoration none
      &:hover
        &:after
          transform scale(1, 1)
      &:after
        position absolute
        bottom -4px
        left 0
        content ''
        width 100%
        height 1px
        background #333
        transform scale(0, 1)
        transform-origin center top
        transition transform .3s
  +mediaSp()
    display none

/* ==========================================================================*/
/* コンテンツ */
/* ==========================================================================*/

.l_commonWrap01
  .l_commonInner
    background url(image_path + "/backImg.png") center 14px no-repeat
    background-size 100%
    margin-top 90px
    +mediaSp()
      width auto
      background-size 150%
    .l_commonBox
      width 1000px
      margin auto
      +mediaSp()
        width 92%
        margin auto 4%
      h3
        font-size 2.4rem
        padding-top 30px
        text-align center
        margin-bottom 80px
        +mediaSp()
          padding-top 20px
          margin-bottom 30px
    &.conceptBox
      text-align center
      h3
        background url(image_path + "/conceptTxt.png") center top no-repeat
        +mediaSp()
          background-size 60%
      &:after
       content ''
       background themeColor01
       height 340px
       width 100%
       display block
      p
        line-height 1.8
      +mediaSp()
        &:after
         height 290px

      .l_commonBox
        width 100%
        margin auto
        &:after
          content ''
          background url(image_path + "/conceptBoxImg.jpg") left bottom no-repeat
          background-size cover
          height 250px
          width 100%
          display block
          margin-top 100px
        p
          width 1000px
          margin auto
          margin-top 10px
        +mediaSp()
          &:after
            height 120px
            width 100%
            display block
            margin-top 40px
          p
            width 92%
            margin auto
            margin-top 15px
            letter-spacing -0.7px
    &.supportBox
      margin-top -220px
      h3
        background url(image_path + "/supportTxt.png") center top no-repeat
      .comingsoonBox
        box-shadow 0px 0px 6px 1px #dedede
        background themeWhite
        padding 80px 0
        text-align center
        color #ababab
        font-size 3.2rem
        +mediaSp()
          font-size 1.8rem
      ul.supportList
        box-shadow 0px 0px 6px 1px #dedede
        background themeWhite
        padding 40px 0
        letter-spacing -.4em
        +mediaSp()
          padding 20px 0
        li
          display inline-block
          letter-spacing normal
          width 33.33333%
          width -webkit-calc(100% / 3)
          width calc(100% / 3)
          text-align center
          vertical-align middle
          +mediaSp()
            width 50%
            img
              width 85%
              display block
              margin auto
      +mediaSp()
        h3
          background-size 60%
    &.teamBox
      background linear-gradient(90deg,themeWhite 0%,themeWhite 25%,themeColor02 0%,themeColor02 100%)
      padding-bottom 150px
      +mediaSp()
        padding-bottom 50px
      .l_commonBox
        width 100%
        background url(image_path + "/backImg.png") center 45px no-repeat
        background-size 100%
        padding-top 20px
        +mediaSp()
          width 100%
          margin auto 0
          background-size 150%
      h3
        width 1000px
        text-align left
        margin 0 auto 40px auto
        background url(image_path + "/teamTxt.png") left top no-repeat
        +mediaSp()
          width 92%
          margin 0 4% 35px 4%
          background-size 34%
      ul.teamList
        width 1000px
        margin auto
        letter-spacing -.4em
        +mediaSp()
          width 100%
        li
          text-align right
          display inline-block
          letter-spacing normal
          width 33.33333%
          width -webkit-calc(100% / 3)
          width calc(100% / 3)
          text-align center
          vertical-align top
          margin-top 40px
          .txtBox
            text-align left
            background-color rgba(21,38,78,0.8)
            margin 0 40px 0 0
            margin-top -150px
            padding 10px
            position relative
            color themeWhite
            +mediaPc()
              height 295px
            h4
              font-size 2.2rem
              border-bottom 1px solid themeWhite
              line-height 1.4
              margin-bottom 10px
              padding-bottom 10px
              span
                font-size 1.4rem
                display block
                font-weight normal
            p
              font-size 1.1rem
              line-height 1.6
          +mediaSp()
            width 92%
            margin 0 4% 40px
            display flex
            justify-content start
            .imgBox
              width 40%
              margin-top 20px
              img
                width 100%
            .txtBox
              width 65%
              margin 0 0 0 -5%
              h4
                font-size 1.8rem
                span
                  font-size 1.2rem
              p
                font-size 1.2rem
    &.companyBox
      h3
        background url(image_path + "/companyTxt.png") center top no-repeat
        +mediaSp()
          background-size 60%
      ul.companyList
        li
          border-bottom 1px solid line01
          padding 15px
          +mediaSp()
            padding 0
          dl
            font-size 1.4rem
            display flex
            justify-content start
            align-items center
            +mediaSp()
              display block
            dt
              font-weight bold
              padding 10px 20px
              width 200px
            dd
              width 770px
            +mediaSp()
              dt
                width 100%
                padding 10px 0 0
              dd
                padding 0 0 10px
                width 100%
             
    &.newsBox
      background themeColor01
      padding 70px 0
      +mediaSp()
        padding 50px 0
      .l_commonBox
        background themeWhite
        display flex
        justify-content start
        align-items center
        padding 20px
        +mediaSp()
          display block
      h3
        background url(image_path + "/newsTxt.png") center top no-repeat
        +mediaSp()
          background-size 35%
        padding 20px 0 0 0
        width 25%
        margin-bottom 0
        +mediaSp()
          width 100%
      ul.newsList
        width 70%
        +mediaSp()
          width 100%
        li
          border-bottom 1px solid line01
          padding 10px
          &:last-child
            border-bottom none
          dl
            display flex
            justify-content start
            align-items center
            font-size 1.4rem
            +mediaSp()
              display block
            dt
              color themeColor04
              font-weight bold
              width 20%
            dd
              width 80%
            +mediaSp()
              dt
                width 100%
              dd
                width 100%